import {post,get} from './axios'
import store from '../store'

// 是否使用新接口
export const getScrap = (params) => {
    return get(`/index/getScrap`,params)
}

// 获取登录注册配置
export const getLoginRegSys = (params) => {
    return get(`/index/login_register`,params)
}

// 登录
export const login = (params) => {
    return post(`/login/loginV2`,params)
}

// 退出登录
export const logout = (params) => {
    return post(`/login/logout`,params)
}

// 手机-邮箱 获取验证码
export const getVerification = (params) => {
    return post(`/login/getVerification`,params)
}

// 图形验证码
export const getGraphicCode = (params) => {
    return post(`/verification/getCode`,params)
}

// 行为验证码
export const getGeetCode = (params) => {
    return get(`/utils/captchatRegister`,params)
}

// 行为验证码验证
export const doGeetValidate = (params) => {
    return post(`/utils/captchaValidateV1`,params)
}

// 获取验证码方式
export const getCodeType = (params) => {
    return get(`/index/verificationV1`,params)
}

// 注册
export const doRegister = (params) => {
    return post(`/login/register`,params)
}

// 在线客服
export const getMaintain = (params) => {
    return get(`/index/maintain`,params)
}

// banner
export const getBanner = (params) => {
    return get(`/index/banner`,params)
}

// 滚动公告，弹窗
export const getBulletin = (params) => {
    return get(`/index/bulletin`,params)
}

// 会员个人信息
export const getMemberInfo = (params) => {
    return get(`/member/info`,params)
}

// 会员买卖单数据
export const getMemberAmount = (params) => {
    return get(`/member/userAmount`,params)
}

// 首页货币订单信息列表
export const getSellList = (params) => {
    return get(`/sell/list`,params)
}

// 获取公共配置
export const getPublicConfig = (params) => {
    return get(`/public/getConfig`,params)
}

// 实名认证
export const doCertification = (params) => {
    return post(`/member/Certification`,params)
}

// 转账
export const doTransfer = (params) => {
    return post(`/pay/transfer`,params)
}

// 修改登录密码
export const changePassword = (params) => {
    return post(`/member/upPassword`,params)
}

// 修改支付密码
export const changePayPassword = (params) => {
    return post(`/member/upPayPassword`,params)
}

// 关于我们
export const getAbout = (params) => {
    return get(`/news/about`,params)
}

// 站内信
export const getNewsList = (params) => {
    return get(`/news/list`,params)
}

// 个人报表
export const getReport = (params) => {
    return get(`/statistics/memberList`,params)
}

// 代理佣金
export const getRebateList = (params) => {
    return get(`/statistics/rebateList`,params)
}

// 资金记录
export const getFundRecord = (params) => {
    return get(`/statistics/amountList`,params)
}

// 修改头像
export const upAvatar = (params) => {
    return post(`/member/upAvatar`,params)
}

// 修改昵称
export const upNickName = (params) => {
    return post(`/member/upNickName`,params)
}

// 上传图片
export const uploadImage = (params) => {
    return post(`/upload/add`,params)
}

// 收款码列表
export const getPaymentList = (params) => {
    return get(`/collection/list`,params)
}

// 收款码银行列表
export const getBankList = (params) => {
    return get(`/collection/bank_list`,params)
}

// 添加收付款方式
export const doAddPayment = (params) => {
    return post(`/collection/add`,params)
}

// 出售货币
export const doSell = (params) => {
    return post(`/sell/addV1`,params)
}

// 系统配置
export const getSysConfig = (params) => {
    return get(`/sys/public`,params)
}

// 购买页面卖家详情
export const getSellerInfo = (params) => {
    return get(`/sell/orderInfo`,params)
}

// 购买货币
export const doBuy = (params) => {
    return post(`/sell/buy`,params)
}

// 买家订单列表
export const getBuyOrderLists = (params) => {
    return get(`/buyOrder/list`,params)
}

// 卖家挂单列表
export const getSellOrderLists = (params) => {
    return get(`/sellOrder/list`,params)
}

// 下架卖家挂单
export const doCancelSellOrder = (params) => {
    return get(`/sellOrder/cancelOrder`,params)
}

// 卖家卖单列表
export const getSellOrderDealLists = (params) => {
    return get(`/sellOrder/son_list`,params)
}

// api订单列表
export const getApiOrderLists = (params) => {
    return get(`/order/list`,params)
}

// 买家订单详情
export const getBuyerOrderDetails = (params) => {
    return get(`/buyOrder/info`,params)
}

// 卖家订单详情
export const getSellerOrderDetails = (params) => {
    return get(`/sellOrder/info`,params)
}

// 订单状态提醒
export const getOrderRemind = (params) => {
    return get(`/member/orderClock`,params)
}

// 卖家确认订单
export const comfirmSellerOrder = (params) => {
    return get(`/sellOrder/confirm`,params)
}

// 卖家取消订单
export const cancelSellerOrder = (params) => {
    return get(`/sellOrder/Cancel`,params)
}

// 买家取消订单
export const cancelBuyerOrder = (params) => {
    return get(`/buyOrder/Cancel`,params)
}

// 买家上传收款凭证
export const uploadPaymentVoucher = (params) => {
    return post(`/buyOrder/certificate`,params)
}

// 买家确认转账
export const comfirmBuyerTransfer = (params) => {
    return get(`/buyOrder/transfer`,params)
}

// 买家查看卖家收款码
export const getSellerCollectionCode = (params) => {
    return get(`/buyOrder/Collection`,params)
}

// 卖家确认打币
export const comfirmSellerPay = (params) => {
    return get(`/sellOrder/payAddV1`,params)
}

// 卖家暂停打币
export const pauseSellerPay = (params) => {
    return get(`/sellOrder/payPauseAdd`,params)
}

// 扫码支付
export const codePay = (params) => {
    return post(`/pay/add`,params)
}

// 维护页
export const getMaintenance = (params) => {
    return get(`/index/maintain`,params)
}

// 第三方支付-支付
export const fromThirdPay = (params) => {
    return post(`/apiV1/pay`,params)
}

// 第三方支付-注册
export const fromThirdReg = (params) => {
    return post(`/apiV1/register`,params)
}

// 获取提示语
export const getHint = (params) => {
    return get(`/index/getHint`,params)
}

//  获取比特币收款汇率
export const getBitRate = () => {
    return get('/bitcoin/getBitcoinConfig')
}

//  获取比特币收款信息
export const getBitInfo = () => {
    return get('/bitcoin/getBitcoinReceipts')
}

//  比特币充值
export const postBitRecharge = (params) => {
    return post(`/bitcoin/orderAdd`, params)
}

//  比特币提现
export const postBitWithdraw = (params) => {
    return post(`/bitcoin/withdrawOrderAdd`, params)
}

//  比特币充值订单列表
export const getBitRechargeOrder = (params) => {
    return get(`/bitcoin/bitcoinOrder`, params)
}

//  比特币提现订单列表
export const getBitWithdrawOrder = (params) => {
    return get(`/bitcoin/withdrawOrder`, params)
}

//  比特币支付密码
export const postWithdrawOrderAddV1 = (params) => {
    return post(`/bitcoin/withdrawOrderAddV1`, params)
}
