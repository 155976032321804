
// 登录成功获取的信息
export const saveLoginInfosMutations = (state, data) => {
    state.loginInfos = data
    window.localStorage.setItem('loginInfos', JSON.stringify(data))
}
// 用户个人资料
export const saveUserInfosMutations = (state, data) => {
    state.userInfos = data
    window.localStorage.setItem('userInfos', JSON.stringify(data))
}
// 系统配置
export const saveSysConfigMutations = (state, data) => {
    state.sysConfig = { ...state.sysConfig,  ...data }
    window.localStorage.setItem('sysConfig', JSON.stringify(state.sysConfig))
}
// 公共配置
export const savePublicConfigMutations = (state, data) => {
    state.publicConfig = data
    window.localStorage.setItem('publicConfig', JSON.stringify(data))
}
// 提交卖币成功后
export const saveSellInfosMutations = (state, data) => {
    state.sellInfos = data
    window.localStorage.setItem('sellInfos', JSON.stringify(data))
}
// 新订单提醒
export const saveOrderRemindMutations = (state, data) => {
    state.orderRemind = data
    window.localStorage.setItem('orderRemind', JSON.stringify(data))
}
// 设置未处理新订单数
export const setUntreatedNum = (state, data) => {
    state.untreatedNum = data
    window.localStorage.setItem('untreatedNum', JSON.stringify(data))
}
// 卖家确认打币后，ws推送的买卖双方金额变化
export const saveMoneyChangeMutations = (state, data) => {
    state.moneyChangeInfos = data
    window.localStorage.setItem('moneyChangeInfos', JSON.stringify(data))
}
// 后台审核已实名
export const saveIsNameMutations = (state, data) => {
    state.isName = data
}
// 用户金额
export const saveMemberAmountMutations = (state, data) => {
    state.memberAmountObj = data
    window.localStorage.setItem('memberAmountObj', JSON.stringify(data))
}
// 主题对应的图片，默认为黑色皮肤
export const saveThemeImgsMutations = (state, data) => {
    state.themeImgs = data
    window.localStorage.setItem('themeImgs', JSON.stringify(data))
}
// 修改皮肤
export const saveThemeMutations = (state, data) => {
    state.theme = data
    window.localStorage.setItem('theme', data)
}
// 从综合传递过来的真实姓名
export const saveActualNameMutations = (state, data) => {
    state.actualName = data
    window.sessionStorage.setItem('actualName', data)
}

// 设置客服弹窗是否开启
export const setShowServicePopup= (state, data) => {
    state.showServicePopup = data
}

// 设置主题色
export const setGlobalSiteTheme= (state, data) => {
    state.globalSiteTheme = data
    window.localStorage.setItem('globalSiteTheme', data)
}

//  设置火币网实时汇率
export const setRealTimeRate = (state, data) => {
    state.realTimeRate = data
}

//  扫码支付是否成功
export const setIsScanSuccess = (state, data) => {
    state.isScanSuccess = data
}
