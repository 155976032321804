// pc端，限制最大宽度为375
if (!/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
  // 当前设备是pc
  const baseSize = 37.5 //跟postcss.config.js中rootValue的值是一致的
  // 设置 rem 函数
  function setRem() {
    // 当前页面宽度相对于 375 宽的缩放比例，可根据自己需要修改。
    const scale = document.documentElement.clientWidth / 375
    // 设置页面根节点字体大小 最高为两倍图 即设计稿为750
    document.documentElement.style.fontSize = (baseSize * Math.min(scale, 1)) + 'px'
  }
  // 初始化
  setRem()
  // 改变窗口大小时重新设置 rem
  window.onresize = function () {
    setRem()
  }
}