const actions = {
    // 登录成功获取的信息
    saveLoginInfosActions({ commit }, payload) {
        commit('saveLoginInfosMutations', payload)
    },
    // 用户个人资料
    saveUserInfosActions({ commit }, payload) {
        commit('saveUserInfosMutations', payload)
    },
    // 系统配置
    saveSysConfigActions({ commit }, payload) {
        commit('saveSysConfigMutations', payload)
    },
    // 公共配置
    savePuclicConfigActions({ commit }, payload) {
        commit('savePublicConfigMutations', payload)
    },
    // 提交卖币成功后
    saveSellInfosActions({ commit }, payload) {
        commit('saveSellInfosMutations', payload)
    },
    // 新订单提醒
    saveOrderRemindActions({ commit }, payload) {
        commit('saveOrderRemindMutations', payload)
    },
    // 卖家确认打币后，ws推送的买卖双方金额变化
    saveMoneyChangeActions({ commit }, payload) {
        commit('saveMoneyChangeMutations', payload)
    },
    // 后台审核已实名
    saveIsNameActions({ commit }, payload) {
        commit('saveIsNameMutations', payload)
    },
    // 用户金额
    saveMemberAmountActions({ commit }, payload) {
        commit('saveMemberAmountMutations', payload)
    },
    // 主题对应的图片，默认为黑色皮肤
    saveThemeImgsActions({ commit }, payload) {
        commit('saveThemeImgsMutations', payload)
    },
    // 修改皮肤
    saveThemeActions({ commit }, payload) {
        commit('saveThemeMutations', payload)
    },
    // 从综合传递过来的真实姓名
    saveActualNameActions({ commit }, payload) {
        commit('saveActualNameMutations', payload)
    }
}
export default actions